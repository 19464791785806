import React from 'react';
import { graphql } from 'gatsby';

// Components
import Layout from '../../components/layout';
import Gallery from '../../components/gallery/gallery';

import routes from '../../constants/routes';

const RemodelPage = ({ data }) => {
    return (
        <Layout slug={routes.gallery_remodels} title="Remodels | Gallery">
            <Gallery data={data} title="Remodels" />
        </Layout>
    );
};

export const query = graphql`
    query {
        allMdx(
            filter: {frontmatter: {category: {eq: "remodel"}}}
            sort: {fields: frontmatter___order, order: ASC}
        ) {
            edges {
                node {
                    frontmatter {
                        cover {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.33)
                            }
                        }
                        images {
                            image {
                                childImageSharp {
                                    gatsbyImageData(placeholder: BLURRED, height: 400)
                                }
                            }
                            caption
                        }
                    }
                }
            }
        }
    }
`;

export default RemodelPage;
